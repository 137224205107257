import MapGL, {
  GeolocateControl,
  LngLatLike,
  NavigationControl,
  useMap,
} from 'react-map-gl';
import { GeoLocationSearch } from './GeoLocationSearch';
import { DrawPolygon } from './DrawPolygon';
import {
  useLayerState,
  useMapViewState,
  usePlotState,
  usePlots,
  useHoveredPlotCallback,
} from '../../hooks';
import { Center, Loader } from '@liveeo/component-library';
import PlotsLayer from './PlotsLayer';
import { GlobalForestLayer } from './GlobalForestLayer';
import { getBbox, BOUNDS_PADDING, hasPrecisionAnalysis } from '../../helper';
import * as Sentry from '@sentry/react';
import { useTableState } from '../../hooks/useTableState';
import { Legend } from './Legend';
import { PrecisionAnalysisLayer } from './PrecisionAnalysisLayer';

export const InteractiveMap = ({ draw, onDrawPlot, onDeletePlot }: any) => {
  const state = usePlotState();
  const { viewState, setViewState } = useMapViewState();
  const { deforestationMap: map } = useMap();
  const { findPlotById } = usePlots();
  const { tableState, setTableState } = useTableState();
  const { setLayerState } = useLayerState();
  const isFetchingMapLocation = Object.values(viewState).every(
    (v) => v === undefined
  );
  const { hoveredFeatureId, clearHoverdFeatureIdMap, setHoveredFeatureIdMap } =
    useHoveredPlotCallback(map);

  return isFetchingMapLocation ? (
    <Center>
      <Loader />
    </Center>
  ) : (
    <MapGL
      reuseMaps
      id="deforestationMap"
      logoPosition="bottom-right"
      style={{ width: '100%', height: '100vh' }}
      initialViewState={viewState}
      cursor={state?.cursor}
      mapStyle="mapbox://styles/app-team/cldyjkcoo006w01kght7pw3ey"
      mapboxAccessToken={import.meta.env['NX_EUDR_MAPBOX_ACCESS_TOKEN']}
      attributionControl={false}
      // Add layer ids here if you want to use them in the interactive map methods (onClick, onMouseEnter etc.)
      interactiveLayerIds={['plot-fill']}
      onMouseMove={(e) => setHoveredFeatureIdMap(e, hoveredFeatureId)}
      onMouseLeave={(e) => clearHoverdFeatureIdMap(e, hoveredFeatureId)}
      onMove={({ viewState: { longitude, latitude, zoom } }) =>
        setViewState({ longitude, latitude, zoom })
      }
      onClick={(e: any) => {
        if (state?.enableClick) {
          if (e.features.length) {
            const plot = findPlotById(e.features[0].properties.plotId);

            if (plot && plot.id !== tableState.sel) {
              const bbox = getBbox(plot.geometry);
              if (bbox.length) {
                map?.fitBounds(bbox as [LngLatLike, LngLatLike], {
                  duration: 0,
                  padding: BOUNDS_PADDING,
                });
              }
              setTableState({ sel: plot.id });

              // We toggle the precision analysis layer visibility when relevant.
              setLayerState({
                precision: hasPrecisionAnalysis(plot) ? true : false,
              });
            }
          } else {
            // Reset table state and turn precision layer off when user clicks outside of plot-fill layer
            setLayerState({ precision: false });
            setTableState({
              sel: undefined,
              ex: undefined,
            });
          }
        }
      }}
      onError={(e) => Sentry.captureException(e.error)}
    >
      <GeoLocationSearch position="top-right" />
      <GeolocateControl position="bottom-right" />
      <NavigationControl position="bottom-right" showCompass={true} />
      <DrawPolygon
        draw={draw}
        onDrawPlot={onDrawPlot}
        onDeletePlot={onDeletePlot}
      />
      <PlotsLayer />
      <GlobalForestLayer />
      <PrecisionAnalysisLayer />
      <Legend />
    </MapGL>
  );
};
